.App {
  text-align: center;
  background-color: #282c34;
  color: white;
  min-height: 100vh;
}

.App h1 {
  margin: 0px;
  padding: 2%;
  font-family: "Brush Script MT", cursive; 
  font-size: 10vh;
}
