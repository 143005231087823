#task-button {
  height: 10vh;
  width: 60%;
  font-size: 2vh;
  font-family: "Candara"; 
  margin-top: 1%;
}

#task-modal {
  font-family: "Candara"; 
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  padding: 3%;
}
