#add-task-button {
  height: 10vh;
  width: 60%;
  font-size: 3vh;
  font-family: "Candara"; 
  border: dashed;
}

#add-task-modal {
  background-color: #282c34;
  font-family: "Candara"; 
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 40%;
  padding: 3%;
}

#add-task-modal label{
  color: white;
}

#add-task-modal .MuiInputBase-input {
  color: white;
} 

#add-task-modal svg {
  fill: white;
}

.add-task-list {
  background-color: #282c34;
  color: white;
}

.add-task-action-buttons {
  padding: 2%
}
